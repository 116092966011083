<template>
  <div class="condition-row">
    <div>
      <span>Si&nbsp;:</span>
    </div>

    <div class="field required">
      <label for="conditioning-field">
        Champ conditionnel
      </label>
      <div>
        <Dropdown
          :options="customFormOptions"
          :selected="conditionField"
          :selection.sync="conditionField"
          :search="true"
          :clearable="true"
          name="conditioning-field"
        />
      </div>
    </div>

    <div>
      <span>=</span>
    </div>

    <div class="field required">
      <label for="conditioning-value">
        Valeur conditionnelle
      </label>
      <div>
        <FeatureExtraForm
          v-if="conditioningCustForm"
          :id="conditioningCustForm.label"
          ref="extraForm"
          class="full-width"
          name="conditioning-value"
          :field="{...conditioningCustForm, value: config.conditionValue}"
          :is-conditional-field="true"
          @update:condition-value="updateConditionValue($event)"
        />
      </div>
    </div>
    
    <div>
      <span>
        Alors
        <span v-if="isForcedValue">&nbsp;:</span>
        <span v-else>&nbsp;le champ est activé</span>
      </span>
    </div>
    
    <div
      v-if="isForcedValue"
      class="field required"
    >
      <label for="forced-value">
        Valeur forcée
      </label>
      <div>
        <FeatureExtraForm
          :id="`forced-value-for-${customForm.name}`"
          ref="extraForm"
          class="full-width"
          name="forced-value"
          :field="{...customForm, value: config.forcedValue}"
          :is-conditional-field="true"
          @update:condition-value="updateForcedValue($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import FeatureExtraForm from '@/components/Feature/Edit/FeatureExtraForm';

export default {
  name: 'CustomFormConditionalField',

  components: {
    Dropdown,
    FeatureExtraForm,
  },

  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    storedCustomForm: {
      type: Object,
      default: () => {}
    },
    customForms: {
      type: Array,
      default: () => []
    },
    isForcedValue: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      customFormOptions: [],
      unsubscribe: null,
    };
  },

  computed: {
    customForm () {
      // if the customForm has been updated in the store return it
      if (this.storedCustomForm && this.storedCustomForm.name) return this.storedCustomForm;
      // else if the custom for is not yet in store, build the same structure as a stored one to pass it to featureExtraForm component
      let customFormInCreation = {};
      for (const el in this.form) {
        customFormInCreation[el] = this.form[el].value;
      }
      return customFormInCreation;
    },
    conditioningCustForm() {
      return this.customForms.find((custForm) => custForm.name === this.config.conditionField) || null;
    },

    isListField() {
      return ['list', 'pre_recorded_list', 'multi_choices_list'].includes(this.conditioningCustForm.field_type);
    },
      
    conditionField: {
      get() {
        return this.conditioningCustForm ? this.formatOptionLabel(this.conditioningCustForm) : '';
      },
      set(newValue) {
        //* set the value selected in the dropdown
        const newConfig = newValue ? { conditionField: newValue.value } : {};
        if (this.config.dataKey) { // forced value being a list, a consistent identifier is needed by Vue to track component thus we use a dataKey
          newConfig['dataKey'] = this.config.dataKey; // and this dataKey need to be kept across modification
        }
        if (this.config.forcedValue !== undefined) {
          // forced value depend on customForm type and won't change here, since it is set when adding the condition, we neet to keep it
          newConfig['forcedValue'] = this.config.forcedValue;
        }
        this.$emit('update:config', newConfig);
      },
    },
  },

  mounted() { // listening to store mutation, since changes of property nested in customForms are not detected
    this.updateCustomFormOptions();
    this.unsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'feature-type/UPDATE_CUSTOM_FORM') {
        this.updateCustomFormOptions();
      }
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  methods: {
    formatOptionLabel: (custForm) => `${custForm.label} (${custForm.name})`,

    updateCustomFormOptions() {
      this.customFormOptions = this.customForms
        .filter((custForm) => (
          custForm.label && custForm.name && // check that customForm has defined properties
          custForm.name !== this.customForm.name && // filter out this customForm itself
          custForm.field_type !== 'char' && custForm.field_type !== 'text'
        ))
        .map((custForm) => {
          return {
            name: this.formatOptionLabel(custForm),
            value: custForm.name,
          };
        });
    },

    updateConditionValue(conditionValue) {
      this.$emit('update:config', { ...this.config, conditionValue });
    },

    updateForcedValue(forcedValue) {
      this.$emit('update:config', { ...this.config, forcedValue });
    }
  }
};

</script>

<style scoped>
.condition-row {
  display: flex;
}
.condition-row > div {
  margin-right: .75em !important;
}
.condition-row > div.field > div {
  display: flex;
  align-items: center;
  min-height: 2.8em;
}
.condition-row > div:not(.field) {
  transform: translateY(2.5em);
}
</style>